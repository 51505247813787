body {
  margin: 0;
  background-color: #fafbff;
}

body * {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
